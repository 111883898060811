.Collapsible__trigger {
  cursor: pointer;
  display: block;
  font-weight: 400;
  text-decoration: none;
  position: relative;
  border: 1px solid white;
  padding: 10px;
  background: #1677ff;
  color: white;
  width: 75%;
  margin: 0 auto;
}

.Collapsible {
  margin-top: 25px;
}
